import '../css/app.pcss';


/* ------------------------------------------------
CORE:
Load any critical scripts first, including functionality
that exists on every page.
------------------------------------------------*/
import accessibility from './modules/globals/accessibility.js';
// import animations from './modules/globals/animations.js';
import burgerMenu from './modules/globals/hamburger_menu.js';

console.log('[WEB-APP] Javascript has been loaded.');

// Lazy Load images
import LazyLoad from "vanilla-lazyload";
const lazyContent = new LazyLoad({});

/* ==========================================================================
========================================================================== */

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}